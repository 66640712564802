var InteractionTypeImpl = InteractionType.extend({

  init: function(blueConicClient, context) {
    this.blueConicClient = blueConicClient;
    this.context = context ? context : null;

    this.changePermissionLevel = false;

    this.OBJECTIVE_ID = "iab_purpose_";

    this.C_OBJECTIVE_ID = "purpose_";
    this.COOKIE_NAME = "OptanonConsent";
    this.COOKIE_GROUP = "groups";
  },

  /**
   *
   */
  onPrepare: function() {
    this._handleConsentCookie(true);
  },

  onLoad: function() {
    this._subscribeConsentOnChanged();
  },

  _subscribeConsentOnChanged: function() {
    if (window.OneTrust && window.OneTrust.OnConsentChanged) {
      var me = this;
      window.OneTrust.OnConsentChanged(function() {
        me._handleConsentCookie();
      });
    }
  },

  /**
   * Handler to convert the consent cookie to BlueConic objectives
   * @param {String} cookie
   */
  _handleConsentCookie: function(prepare) {
    try {
      var cookie = this.blueConicClient.util.cookie.getCookie(this.COOKIE_NAME);
      if (!cookie) {
        // do nothing or refuse all purposes?
        return;
      }
      // Convert the string to a cookie object
      var cookieObject = this._consentCookieToObject(cookie);

      // retrieve the purposes from the cookie
      // var purposes = this._getIABPurposes(cookieObject);
      var purposes = this._getCPurposes(cookieObject);

      // set the BlueConic objectives based of the purposes
      this._setObjectives(purposes, prepare);

    } catch (err) {
      // do nothing
    }
  },

  /**
   * Transform the consent cookie string to an usable object.
   * @param {String} cookie
   */
  _consentCookieToObject: function(cookie) {
    var result = {};
    if (cookie) {
      var cookieSplit = cookie.split("&");
      for (var i = 0; i < cookieSplit.length; i++) {
        var item = cookieSplit[i].split("=");
        if (item[0] && item[1]) {
          result[item[0]] = item[1];
        }
      }
    }

    return result;
  },

  /**
   * Return the purposes from the cookie
   * @param {*} cookieObject
   */
  _getIABPurposes: function(cookieObject) {
    var result = {};
    if (!cookieObject || !cookieObject[this.COOKIE_GROUP]) {
      return result;
    }

    // "0_47572:1,1:1,2:0,4:0,0_72602:1,0_116028:1,0_115966:1,0_116019:1,0_47573:1,0_69893:1,0_81449:1,0_116024:1,0_102673:1,0_112685:1,0_105905:1,0_112074:1,0_112682:1,0_71263:1,0_105904:1,0_112683:1,0_47569:1,0_47568:1,0_112681:1"
    var groups = cookieObject[this.COOKIE_GROUP].split(",");

    for (var i = 0; i < groups.length; i++) {
      if (groups[i] && groups[i].length === 3) {
        // valid purposes are always 3 characters long: 1:1, 2:0, 4:0
        var item = groups[i].split(":");
        if (item[0] !== null && item[1] !== null && result[item[0]] === undefined) {
          // Do not override purposes
          result[item[0]] = parseInt(item[1]) === 1;
        }
      }
    }

    return result;
  },

    /**
   * Return the purposes from the cookie
   * @param {*} cookieObject
   */
  _getCPurposes: function(cookieObject) {
    var result = {};
    if (!cookieObject || !cookieObject[this.COOKIE_GROUP]) {
      return result;
    }

    // "0_47572:1,1:1,2:0,4:0,0_72602:1,0_116028:1,0_115966:1,0_116019:1,0_47573:1,0_69893:1,0_81449:1,0_116024:1,0_102673:1,0_112685:1,0_105905:1,0_112074:1,0_112682:1,0_71263:1,0_105904:1,0_112683:1,0_47569:1,0_47568:1,0_112681:1"
    var groups = decodeURIComponent(cookieObject[this.COOKIE_GROUP]).split(",");

    for (var i = 0; i < groups.length; i++) {
      if (groups[i] && groups[i].indexOf("C000") === 0) {
        // valid purposes are always starts with C000 characters: C0001:1, C0002:0, C0004:0
        var item = groups[i].split(":");
        if (item[0] !== null && item[1] !== null && result[item[0]] === undefined) {
          // Do not override purposes
          result[item[0]] = parseInt(item[1]) === 1;
        }
      }
    }

    return result;
  },

  /**
   * Consent/refuse objectives
   * @param {String[]} groups
   * @param {Boolean} prepare
   */
  _setObjectives: function(groups, prepare) {
    var profile = this.blueConicClient.profile.getProfile();
    for (var key in groups) {
      var objective = this.C_OBJECTIVE_ID + key.toLowerCase();
      groups[key] ?
        profile.addConsentedObjective(objective) :
        profile.addRefusedObjective(objective);

      // purpose 4 is Targeting
      // if (this.changePermissionLevel && key === "4") {
      //   profile.permission.setLevel(groups[key] ?
      //     "PERSONAL" :
      //     "ANONYMOUS"
      //   );
      // }
    }

    if (!prepare) {
      this.blueConicClient.profile.updateProfile();
    }
  }
});
